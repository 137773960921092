@import "~@/styles/variables/variables.scss";






































































































































































































































































.page-main {
	&-left {
		.box-select {
			margin-top: 39px;
		}
		.items {
			margin-top: 30px;
			overflow-y: auto;
			height: calc(100% - 144px);
			.item {
				cursor: pointer;
				height: 40px;
				line-height: 40px;
				padding: 0 13px;
				background: #f0f0f0;
				font-weight: 400;
				color: #666666;
				border-radius: 8px;
				&.active {
					background: #ebedfa;
					color: #4d6bff;
				}
				+ .item {
					margin-top: 10px;
				}
			}
		}
	}
	&-right {
		::v-deep .el-form {
			padding: 25px;
			padding-bottom: 0;
			.el-form-item {
				display: flex;
				&__content {
					display: flex;
					align-items: center;
				}
			}
			.el-form-item__label {
				color: #ababab;
			}
			.el-radio-group {
				margin-left: 36px;
			}
			.title {
				margin-top: 30px;
				color: #ababab;
				position: relative;
				&::after {
					content: '';
					position: absolute;
					left: 60px;
					right: 0;
					top: 50%;
					transform: translateY(-50%);
					border-bottom: 1px solid #eef0f3;
				}
			}
			.model-info {
				margin-top: 18px;
				.el-form-item__label {
					color: #666;
				}
				.el-input {
					max-width: 260px;
				}
			}
			.unit {
				margin-left: 10px;
				color: #ccc;
			}
			.el-checkbox {
				display: block;
				margin-top: 10px;
				width: 150px;
				&:first-of-type {
					margin-top: 20px;
				}
			}
		}
	}
}
::v-deep {
	.el-button.btn-save {
		width: 100px;
		height: 32px;
		border-radius: 16px !important;
	}
}
